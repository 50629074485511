import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Props } from './type';

const ICON_SIZE = 24;

const PowerButton: React.SFC<Props> = ({ onClick, powerOn = true }) => {
  const [active, setActive] = useState(powerOn);

  useEffect(() => {
    if (active !== powerOn) {
      setActive(powerOn);
    }
  }, [powerOn]);

  const handleOnClick = () => {
    onClick(!powerOn);
  };

  const handleOnChange = () => {
    setActive(!active);
  };

  return (
    <div className={powerOn ? styles.container : styles.containerOff}>
      <div
        className={powerOn ? styles.iconWrapper : styles.iconWrapperOff}
        onClick={handleOnClick}
        onTouchStart={handleOnChange}
        onTouchEnd={handleOnChange}
        onMouseDown={handleOnChange}
        onMouseUp={handleOnChange}
      >
        <PowerSettingsNewRoundedIcon
          htmlColor={active ? styles.colorOn : styles.colorOff}
          style={{ height: ICON_SIZE, width: ICON_SIZE }}
        />
      </div>
    </div>
  );
};

export default PowerButton;
