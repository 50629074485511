import React from 'react';
import styles from './style.module.scss';
import { Props } from './type';
import GamePanel from 'src/component/game-panel';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import useGameBreakout from 'src/hook/use-game-breakout';

const ICON_SIZE = 20;

const BottomPanel: React.SFC<Props> = ({ powerOn }) => {
  const { gameOver, lives, onReset, score, winner } = useGameBreakout('game-canvas');

  return (
    <div className={styles.container}>
      <GamePanel gameOver={gameOver} onReset={onReset} powerOn={powerOn} score={score} winner={winner}>
        <div className={styles.rightPanel}>
          <div className={styles.rightPanelLives}>
            <div className={styles.lives}>
              {powerOn && lives > 2 ? (
                <FavoriteRoundedIcon
                  htmlColor={'#e93c3b'}
                  style={{ height: ICON_SIZE, marginTop: 4, width: ICON_SIZE }}
                />
              ) : (
                <FavoriteBorderRoundedIcon
                  htmlColor={'#4B4E59'}
                  style={{ height: ICON_SIZE, marginTop: 4, width: ICON_SIZE }}
                />
              )}
              {powerOn && lives > 1 ? (
                <FavoriteRoundedIcon
                  htmlColor={'#e93c3b'}
                  style={{ height: ICON_SIZE, marginTop: 8, width: ICON_SIZE }}
                />
              ) : (
                <FavoriteBorderRoundedIcon
                  htmlColor={'#4B4E59'}
                  style={{ height: ICON_SIZE, marginTop: 8, width: ICON_SIZE }}
                />
              )}
              {powerOn && lives > 0 ? (
                <FavoriteRoundedIcon
                  htmlColor={'#e93c3b'}
                  style={{ height: ICON_SIZE, marginTop: 8, width: ICON_SIZE }}
                />
              ) : (
                <FavoriteBorderRoundedIcon
                  htmlColor={'#4B4E59'}
                  style={{ height: ICON_SIZE, marginTop: 8, width: ICON_SIZE }}
                />
              )}
            </div>
            <span className={`${styles.title} ${styles.titleTop}`}>Lives</span>
          </div>
          <div className={styles.rightPanelFooter}>
            <span className={styles.number}>{powerOn ? score : 0}</span>
            <span className={`${styles.title} ${styles.titleBottom}`}>Score</span>
          </div>
        </div>
      </GamePanel>
    </div>
  );
};

export default BottomPanel;
