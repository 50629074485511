import React, { useState } from 'react';
import useFontLoader from 'src/hook/use-font-loader';
import useWindowSize from 'src/hook/use-windows-size';
import styles from './App.module.scss';
import Polaroid from 'src/component/polaroid';
import VideoComputerSystem from 'src/component/video-computer-system';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Drawer from '@material-ui/core/SwipeableDrawer';

const MAX_FRAME_SIZE = 680;
const MIN_FRAME_SIZE = 560;
const MOBILE_BREACKPOINT = 480;

const footer = () => {
  return (
    <ul className={styles.menuMobile}>
      <li className={styles.menuItem}>
        <a href="https://github.com/alejandroweb" rel="noopener noreferrer" target="_blank">
          Github
        </a>
      </li>
      <li className={styles.menuItem}>
        <a href="https://www.linkedin.com/in/alejandrodriguez/" rel="noopener noreferrer" target="_blank">
          Linkedin
        </a>
      </li>
      <li className={styles.menuItem}>
        <a href="https://twitter.com/alejandroweb" rel="noopener noreferrer" target="_blank">
          Twitter
        </a>
      </li>
      <li className={styles.menuItem}>
        <a href="https://www.instagram.com/alejandroweb/" rel="noopener noreferrer" target="_blank">
          Instagram
        </a>
      </li>
    </ul>
  );
};

const App: React.FC = () => {
  const [drawer, setDrawer] = useState(false);
  const [ready] = useFontLoader();
  const {
    rectangle: [width, height],
  } = useWindowSize();

  const calculateSizes = () => {
    if (width < MOBILE_BREACKPOINT) {
      return ['100%', '100%'];
    } else {
      const newHeight = height * 0.78 > MAX_FRAME_SIZE ? MAX_FRAME_SIZE : height * 0.78;
      const minNewHeight = newHeight < MIN_FRAME_SIZE ? MIN_FRAME_SIZE : newHeight;
      const w = (5 * minNewHeight) / 3;

      return [`${w}px`, `${minNewHeight}px`];
    }
  };

  const [w, h] = calculateSizes();

  const handleOnSetDrawer = () => {
    setDrawer(!drawer);
  };

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <h1>aro.io</h1>
      </header>
      <Polaroid />
      <footer className={styles.footer}>
        <div className={styles.menuMobileWrapper}>
          <MenuRoundedIcon htmlColor={'white'} onClick={handleOnSetDrawer} />
          <Drawer anchor="right" open={drawer} onClose={handleOnSetDrawer} onOpen={handleOnSetDrawer}>
            {footer()}
          </Drawer>
        </div>
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <a href="https://github.com/alejandroweb" rel="noopener noreferrer" target="_blank">
              Github
            </a>
          </li>
          <li className={styles.menuItem}>
            <a href="https://www.linkedin.com/in/alejandrodriguez/" rel="noopener noreferrer" target="_blank">
              Linkedin
            </a>
          </li>
          <li className={styles.menuItem}>
            <a href="https://twitter.com/alejandroweb" rel="noopener noreferrer" target="_blank">
              Twitter
            </a>
          </li>
          <li className={styles.menuItem}>
            <a href="https://www.instagram.com/alejandroweb/" rel="noopener noreferrer" target="_blank">
              Instagram
            </a>
          </li>
        </ul>
      </footer>
      <VideoComputerSystem />
    </div>
  );
};

export default App;
