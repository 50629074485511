import React, { useEffect } from 'react';
import styles from './style.module.scss';
import { Props } from './type';
import BabeGif from './img/baby.gif';
import Fade from 'react-reveal/Fade';

const GamePanel: React.SFC<Props> = ({ children, gameOver, onReset, powerOn, score, winner = true }) => {
  useEffect(() => {
    if (!powerOn) {
      onReset();
    }
  }, [powerOn]);

  return (
    <div className={styles.container}>
      <div id="game-canvas" style={{ borderRadius: 8, maxHeight: 360 }} />
      {winner ? (
        <Fade duration={300}>
          <div className={styles.winner}>
            <span className={styles.winnerTitle}>You won!!</span>
            <span className={styles.winnerScore}>{`Score: ${score}`}</span>
            <img alt="Winner" className={styles.winnerGif} src={BabeGif} />
            <span className={styles.winnerContinue} onClick={onReset}>
              Retry >>
            </span>
          </div>
        </Fade>
      ) : null}
      {gameOver ? (
        <Fade duration={300}>
          <div className={styles.gameOver}>
            <span className={styles.gameOverTitle}>Game Over</span>
            <span className={styles.gameOverScore}>{`Score: ${score}`}</span>
            <span className={styles.gameOverContinue} onClick={onReset}>
              Retry >>
            </span>
          </div>
        </Fade>
      ) : null}
      {!powerOn ? (
        <Fade duration={300}>
          <div className={styles.powerOff}>
            <p>
              <span aria-label="Hi" role="img">
                👋&nbsp;
              </span>
              Hi there!
            </p>
            <p>
              I’m Alejandro, a React geek who sometimes ends up making video games like this one. Music is my happy
              place, I have a weakness for comic books and obviously cats 😻.
            </p>
            <p>
              If any of this sounds interesting to you... <br /> Let’s chat!&nbsp;
              <a href="mailto:hi@aro.io">hi@aro.io</a>
            </p>
          </div>
        </Fade>
      ) : null}
      {children}
    </div>
  );
};

export default React.memo(GamePanel);
