import React, { useState } from 'react';
import BottomPanel from 'src/component/bottom-panel';
import FrontPanel from 'src/component/front-panel';
import styles from './style.module.scss';

const VideoComputerSystem = () => {
  const [powerOn, setPowerOn] = useState(true);

  return (
    <div className={styles.container}>
      <FrontPanel onClickPower={setPowerOn} powerOn={powerOn} />
      <BottomPanel powerOn={powerOn} />
    </div>
  );
};

export default VideoComputerSystem;
